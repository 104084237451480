import React, { useState } from "react";
import "./style.css";
import { NavLink } from "react-router-dom";
import { authSelector } from "../../app/features/Auth/authSlice";
import { useSelector } from "react-redux";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai"

const Sidebar = () => {
	const { toggleActive } = useSelector(authSelector);
	const [active, setActive] = useState("false");
	const { type } = useSelector(authSelector);

	const handleclick = () => {
		if (document.body.classList.contains("body_toggle")) {
			document.body.classList.remove("body_toggle");
		} else {
			document.body.classList.add("body_toggle");
		}
		setActive(!active);
	};

	const permission = JSON.parse(localStorage.getItem('userdata'));

	return (
		<div
			className={active ? "sidebar_outer toggle" : "sidebar_outer"}
		>
			<div className="sidebar_inner">
				<div className="site_logo ">
					<span className="logo_icon">
						<img src="/assets/images/logo.png" alt="" />
					</span>
					<span className="logo_text">
						<img src="/assets/images/logo_text.png" alt="" srcSet="" />
					</span>
					{active ? <AiFillCaretRight onClick={handleclick} /> : <AiFillCaretLeft onClick={handleclick} />}
				</div>
				<ul>
					{<li title="Dashboard">
						{Object.values(permission.Permissions).includes('Overview') && <NavLink to="/home">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M12.75 9.75V3H21v6.75h-8.25zm-9.75 3V3h8.25v9.75H3zM12.75 21v-9.75H21V21h-8.25zM3 21v-6.75h8.25V21H3zm1.5-9.75h5.25V4.5H4.5v6.75zm9.75 8.25h5.25v-6.75h-5.25v6.75zm0-11.25h5.25V4.5h-5.25v3.75zM4.5 19.5h5.25v-3.75H4.5v3.75z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Dashboard</span>
						</NavLink>}
					</li>}
					{Object.values(permission.Permissions).includes('Overviewsaas') && <li title="Dashboard">
						<NavLink to="/homeSaas">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M12.75 9.75V3H21v6.75h-8.25zm-9.75 3V3h8.25v9.75H3zM12.75 21v-9.75H21V21h-8.25zM3 21v-6.75h8.25V21H3zm1.5-9.75h5.25V4.5H4.5v6.75zm9.75 8.25h5.25v-6.75h-5.25v6.75zm0-11.25h5.25V4.5h-5.25v3.75zM4.5 19.5h5.25v-3.75H4.5v3.75z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Dashboard Saas</span>
						</NavLink>
					</li>}
					{Object.values(permission.Permissions).includes('viewreach') && <li title="Reach Calculator">
						<NavLink to="/abstract">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M.95 20v-2.35c0-.583.15-1.112.45-1.587.3-.475.717-.83 1.25-1.063 1.217-.533 2.313-.917 3.288-1.15.975-.233 1.979-.35 3.012-.35s2.033.117 3 .35c.967.233 2.058.617 3.275 1.15a2.854 2.854 0 0 1 1.725 2.65V20h-16zm17.5 0v-2.35c0-1.05-.267-1.912-.8-2.587-.533-.676-1.233-1.221-2.1-1.638 1.15.133 2.233.33 3.25.587 1.017.259 1.842.555 2.475.888.55.317.983.708 1.3 1.175.317.467.475.992.475 1.575V20h-4.6zm-9.5-8.025c-1.1 0-2-.35-2.7-1.05-.7-.7-1.05-1.6-1.05-2.7s.35-2 1.05-2.7c.7-.7 1.6-1.05 2.7-1.05s2 .35 2.7 1.05c.7.7 1.05 1.6 1.05 2.7s-.35 2-1.05 2.7c-.7.7-1.6 1.05-2.7 1.05zm9-3.75c0 1.1-.35 2-1.05 2.7-.7.7-1.6 1.05-2.7 1.05-.183 0-.387-.012-.612-.037a2.647 2.647 0 0 1-.613-.138c.4-.417.704-.93.912-1.538.209-.608.313-1.287.313-2.037s-.104-1.413-.313-1.988a5.314 5.314 0 0 0-.912-1.587 5.274 5.274 0 0 1 1.225-.175c1.1 0 2 .35 2.7 1.05.7.7 1.05 1.6 1.05 2.7zM2.45 18.5h13v-.85c0-.267-.08-.525-.237-.775a1.307 1.307 0 0 0-.588-.525c-1.2-.533-2.208-.892-3.025-1.075-.817-.183-1.7-.275-2.65-.275s-1.837.092-2.662.275c-.825.183-1.838.542-3.038 1.075a1.24 1.24 0 0 0-.575.525c-.15.25-.225.508-.225.775v.85zm6.5-8.025c.65 0 1.188-.213 1.613-.638.425-.425.637-.962.637-1.612 0-.65-.212-1.188-.637-1.613-.426-.425-.963-.637-1.613-.637s-1.187.212-1.612.637c-.425.425-.638.963-.638 1.613s.213 1.187.638 1.612c.425.425.962.638 1.612.638z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Reach Calculator</span>
						</NavLink>
					</li>}
					{Object.values(permission.Permissions).includes('viewcampaign') && <li title="Campaign Manager">
						<NavLink to="/campaign">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M18.25 12.75v-1.5H22v1.5h-3.75zM19.5 20l-3.025-2.25.9-1.2L20.4 18.8l-.9 1.2zM17.45 7.425l-.9-1.2L19.5 4l.9 1.2-2.95 2.225zM5.25 19v-4H3.5c-.413 0-.766-.147-1.06-.44A1.445 1.445 0 0 1 2 13.5v-3c0-.412.147-.766.44-1.06.294-.293.647-.44 1.06-.44H8l5-3v12l-5-3H6.75v4h-1.5zM14 15.35v-6.7c.45.4.813.887 1.088 1.463.275.575.412 1.204.412 1.887s-.137 1.313-.412 1.887A4.567 4.567 0 0 1 14 15.35zM3.5 10.5v3h4.9l3.1 1.85v-6.7L8.4 10.5H3.5z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Campaign Manager</span>
						</NavLink>
					</li>}
					{Object.values(permission.Permissions).includes('viewcampaignsaas') &&
						<li title="Campaign Manager Saas">
							<NavLink to="/campaignsaas">
								<div className="nav_icon">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M3.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-11c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h17c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v11c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-17zm0-1.5h17v-11h-17v11zm6.675-1.775L15.7 15l-5.525-3.7v7.425zM3.725 6.5V5h16.55v1.5H3.725zM7 3.5V2h10v1.5H7z" fill="#989FB3" />
									</svg>
								</div>
								<span className="ml-2">Campaign Manager Saas</span>
							</NavLink>
						</li>
					}
					{Object.values(permission.Permissions).includes('viewAdminSaas') && <li title="Admin Saas">
						<NavLink to="/adminSaas">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M3.5 22c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-11c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h17c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v11c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-17zm0-1.5h17v-11h-17v11zm6.675-1.775L15.7 15l-5.525-3.7v7.425zM3.725 6.5V5h16.55v1.5H3.725zM7 3.5V2h10v1.5H7z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Admin Saas</span>
						</NavLink>
					</li>}

					{Object.values(permission.Permissions).includes('viewreports') && <li title="Reports Fixed Price">
						<NavLink to="/reports">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M8 11.75v-1.5h8v1.5H8zm0-4v-1.5h8v1.5H8zm-2.5 6.5h8c.44 0 .85.1 1.23.3.38.2.703.467.97.8L18.5 19V3.5h-13v10.75zm0 6.25h12.25l-3.225-4.225a1.357 1.357 0 0 0-.457-.386 1.216 1.216 0 0 0-.568-.139h-8v4.75zm13 1.5h-13c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h13c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v17c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Reports Fixed Price</span>
						</NavLink>
					</li>}
					{Object.values(permission.Permissions).includes('viewreportssaas') && <li title="Reports Dynamic">
						<NavLink to="/reportssaas">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M8 11.75v-1.5h8v1.5H8zm0-4v-1.5h8v1.5H8zm-2.5 6.5h8c.44 0 .85.1 1.23.3.38.2.703.467.97.8L18.5 19V3.5h-13v10.75zm0 6.25h12.25l-3.225-4.225a1.357 1.357 0 0 0-.457-.386 1.216 1.216 0 0 0-.568-.139h-8v4.75zm13 1.5h-13c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h13c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v17c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Reports Dynamic</span>
						</NavLink>
					</li>}
					{Object.values(permission.Permissions).includes('viewreportsfinance') && <li title="Reports RC Finance">
						<NavLink to="/report-rc-finance">
							<div className="nav_icon">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
									<path d="M8 11.75v-1.5h8v1.5H8zm0-4v-1.5h8v1.5H8zm-2.5 6.5h8c.44 0 .85.1 1.23.3.38.2.703.467.97.8L18.5 19V3.5h-13v10.75zm0 6.25h12.25l-3.225-4.225a1.357 1.357 0 0 0-.457-.386 1.216 1.216 0 0 0-.568-.139h-8v4.75zm13 1.5h-13c-.4 0-.75-.15-1.05-.45-.3-.3-.45-.65-.45-1.05v-17c0-.4.15-.75.45-1.05.3-.3.65-.45 1.05-.45h13c.4 0 .75.15 1.05.45.3.3.45.65.45 1.05v17c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45z" fill="#989FB3" />
								</svg>
							</div>
							<span className="ml-2">Reports RC Finance</span>
						</NavLink>
					</li>}
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
