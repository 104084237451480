import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./style.css";

const dayLabels = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
const dayShortLabels = ["S", "M", "T", "W", "T", "F", "S"];

const hourLabels = {
  "00:00": "12:00 AM",
  "01:00": "1:00 AM",
  "02:00": "2:00 AM",
  "03:00": "3:00 AM",
  "04:00": "4:00 AM",
  "05:00": "5:00 AM",
  "06:00": "6:00 AM",
  "07:00": "7:00 AM",
  "08:00": "8:00 AM",
  "09:00": "9:00 AM",
  "10:00": "10:00 AM",
  "11:00": "11:00 AM",
  "12:00": "12:00 PM",
  "13:00": "1:00 PM",
  "14:00": "2:00 PM",
  "15:00": "3:00 PM",
  "16:00": "4:00 PM",
  "17:00": "5:00 PM",
  "18:00": "6:00 PM",
  "19:00": "7:00 PM",
  "20:00": "8:00 PM",
  "21:00": "9:00 PM",
  "22:00": "10:00 PM",
  "23:00": "11:00 PM",
};

const listFormatter = new Intl.ListFormat("en", {
  style: "long",
  type: "conjunction",
});

const hourLabelsEntries = Object.entries(hourLabels);

const Time = ({ id, value, setValue }) => {
  return <select id={id} className="form-control" value={value} onChange={(e) => setValue(e.target.value)}>
    {hourLabelsEntries.map(([hour, label]) => (
      <option value={hour} key={hour}>{label}</option>
    ))}
  </select>;
};

function TargetingDayAndTime({ segments, setSegments }) {
  const groupSegments = (segments) => {
    const grouped = {};
    const result = [];
    segments.forEach(({ id, day, from, to, timezone, deleted }) => {
      if (deleted) return;
      const groupKey = from.slice(0, 2) + to.slice(0, 2);
      if (!grouped[groupKey]) {
        grouped[groupKey] = { ids: [id], days: [day], from, to, timezone };
        return;
      }
      const ids = [...grouped[groupKey].ids, id];
      const days = [...grouped[groupKey].days, day];
      grouped[groupKey] = { ids, days, from, to, timezone };
    });
    for (const groupKey in grouped) {
      if (!Object.hasOwn(grouped, groupKey)) continue;
      const group = grouped[groupKey];
      group.days.sort();
      result.push({
        key: group.days + groupKey,
        ids: group.ids,
        days: group.days,
        daysFormatted: listFormatter
          .format(group.days.map((d) => `<strong>${dayLabels[d]}</strong>`)),
        from: group.from,
        to: group.to,
        timezone: group.timezone,
      });
    }
    result.sort((a, b) => a.key.localeCompare(b.key));
    return result;
  };

  const defaultTimezone = 1; // Sao Paulo, Brazil
  const defaultTime = hourLabelsEntries[0][0];
  const [groupedSegments, setGroupedSegments] = useState([]);
  const [days, setDays] = useState(new Set());
  const [from, setFrom] = useState(defaultTime);
  const [to, setTo] = useState(defaultTime);
  const [inputTimezone, setInputTimezone] = useState(defaultTimezone);

  useEffect(() => {
    setGroupedSegments(groupSegments(segments));
    setInputTimezone(segments[0]?.timezone ?? defaultTimezone);
  }, [segments]);

  const addSegments = () => {
    if (!days?.size || from >= to) return;
    const newSegments = [...segments];
    days.forEach((day) => {
      const key = day + from.slice(0, 2) + to.slice(0, 2);
      const existingSegment = newSegments.find((segment) => segment.key === key);
      if (existingSegment) {
        existingSegment.deleted = false;
        return;
      }
      newSegments.push({
        key,
        day,
        from,
        to,
        timezone: inputTimezone,
        deleted: false,
      });
    });

    newSegments.sort((a, b) => (a.day + a.from + a.to)
      .localeCompare(b.day + b.from + b.to));

    const mergedSegments = [];

    for (const segment of newSegments) {
      const mergedSegmentsLength = mergedSegments.length;
      const lastMergedSegment = mergedSegmentsLength > 0
        ? mergedSegments[mergedSegmentsLength - 1]
        : null;
      if (
        lastMergedSegment &&
        lastMergedSegment.day === segment.day &&
        lastMergedSegment.to >= segment.from
      ) {
        const newTo = lastMergedSegment.to > segment.to
          ? lastMergedSegment.to
          : segment.to;
        if (lastMergedSegment.id) {
          lastMergedSegment.deleted = true;
          mergedSegments.push({ ...segment, to: newTo, deleted: false });
        } else {
          lastMergedSegment.to = newTo;
        }
      } else {
        mergedSegments.push(segment);
      }
    }

    setSegments(mergedSegments);
    setGroupedSegments(groupSegments(mergedSegments));
    setDays(new Set());
    setFrom(defaultTime);
    setTo(defaultTime);
  };

  const removeSegments = (group) => {
    const newSegments = segments.map((segment) => {
      const key = segment.day + segment.from.slice(0, 2) + segment.to.slice(0, 2);
      return { ...segment, key };
    });
    group.days.forEach((day) => {
      const key = day + group.from.slice(0, 2) + group.to.slice(0, 2);
      const existingSegmentIndex = newSegments.findIndex((segment) => segment.key === key);
      const existingSegment = newSegments[existingSegmentIndex];
      if (existingSegment.id) {
        existingSegment.deleted = true;
      } else {
        newSegments.splice(existingSegmentIndex, 1);
      }
    });
    setSegments(newSegments);
    setGroupedSegments(groupSegments(newSegments));
  };

  const setTimezone = (timezone) => {
    setInputTimezone(timezone);
    if (!segments.length) return;
    const newSegments = segments.map((segment) => ({ ...segment, timezone }));
    setSegments(newSegments);
  };

  return <>
    <p style={{ fontSize: "16px" }}>Day & Time</p>
    <p>Select all the days that have in common the different opening and closing ranges. To add new days with their schedules, click on “Add segments”.</p>
    <div className="targeting-time mt-4">
      <div className="targeting-time-control">
        <div className="daypart">
          <div className="days">
            <div className="label">Days</div>
            {dayShortLabels.map((label, day) => (
              <label key={day}><input
                type="checkbox"
                value={day}
                checked={days.has(day)}
                onClick={(event) => {
                  const newDays = new Set(days);
                  event.target.checked ? newDays.add(day) : newDays.delete(day);
                  setDays(newDays);
                }}
              />{label}</label>
            ))}
          </div>
          <div class="form-group select_country_container cmn_modal_field mb-3">
            <label class="lbl_position " for="from">From</label>
            <Time id="from" value={from} setValue={setFrom}/>
          </div>
          <div class="form-group select_country_container cmn_modal_field mb-3">
            <label class="lbl_position " for="to">To</label>
            <Time id="to" value={to} setValue={setTo}/>
          </div>
        </div>
        <div className="button">
          <button onClick={addSegments} class="cmn_btn cmn_bordered_btn">
            <FontAwesomeIcon className="ms-2" icon={faPlus} /> &nbsp;
            Add segments
          </button>
        </div>
      </div>
      <div className="dayparts form-group select_country_container cmn_modal_field">
        <div>
          <label class="lbl_position">Chosen dayparts</label>
          <div><label class="lbl_position" for="timezone">Timezone:</label> <select id="timezone" value={inputTimezone} onChange={(e) => setTimezone(e.target.value)}>
            <option value="0">Target country</option>
            <option value="1">Sao Paulo, Brazil</option>
          </select></div>
        </div>
        <ul>
          {groupedSegments.map((group) => (
            <li key={group.key}>
              <span dangerouslySetInnerHTML={{ __html: group.daysFormatted }}></span>,
              from <strong>{hourLabels[group.from]}</strong> to <strong>{hourLabels[group.to]}</strong>&nbsp;
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                class="cursorPointer"
                fill="currentColor"
                width="16"
                onClick={() => removeSegments(group)}
              >
                <path fill="none" opacity=".87" d="M0 0h24v24H0V0z"></path>
                <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z"></path>
              </svg>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </>;
}

export default TargetingDayAndTime;
